
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react"
import { useTranslation } from "react-i18next"
import DefaultLayout from "../../layout";

import * as styles from "../index.module.scss"

const DiscordRulesPage = (props) => {
  const file = props.data.file;

  const {t} = useTranslation();

  return (
    <DefaultLayout title={t("discordRules")}>
      <div className={styles.layoutBox}>
        <h1>{t("discordRules")}</h1>

        <MDXRenderer >{file.childMdx.body}</MDXRenderer>
      </div>
    </DefaultLayout>
  )
}

export default DiscordRulesPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    file(
      sourceInstanceName: {eq: "contentPages"}, relativeDirectory: {eq: "discord/rules"}, name: {eq: $language}
    ) {
      id
      childMdx {
        body
      }
      name
    }
  }
`;